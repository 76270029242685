<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      align="left"
      :inlineLabel="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>ㄴ

<script>
export default {
  name: 'spare-history-tab',
  data() {
    return {
      tab: 'spareHistory',
      tabItems: [
        { name: 'spareHistory', icon: 'colorize', label: '자재 입출고 이력', component: () => import(`${'./spareHistory.vue'}`) },
        { name: 'spareOutStatus', icon: 'colorize', label: '설비별 자재 출고 이력', component: () => import(`${'./spareOutStatus.vue'}`) },
      ],
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      contentHeight: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
    },
  }
};
</script>
